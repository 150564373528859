import React, {useState, useContext} from "react";
import Card from "../../component/Card";
import Button from '../../component/Button';
import MyContext from "../../store/MyData";
import Axios from "axios";

const EmployeeForm = () =>{

    const [empName, setEmployeeName] = useState("");
    const [empNameEmpty, setEmpNameEmpty] = useState(false);
    const myData = useContext(MyContext);

    const empNameHandler = (event) => {
      // event.preventDefault();
      // when i use setTime out function it do not allow to change value of text input 
      // using value={empName}
     //  setTimeout(()=>{
        setEmployeeName(event.target.value);
        console.log("name "+ empName);

     //  },500)
       

    }

    const submitEmployeeData = (event) => {
     event.preventDefault();
     /**
     * form validaton
     */
      if (empName.trim() === "") {
        //  alert("Please Select Employee");
        setEmpNameEmpty(true);
        return;
      }
      setEmpNameEmpty(false);

      
  const FetchRecordHandler_when_data_changed = () => {
    Axios.get(process.env.REACT_APP_BASE_URL + "/emplist")
      .then((responce) => {
        // console.log(responce.data);
        if (responce) {
          
          myData.setEmpRecordF(responce.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }; //end  function


  

     Axios.post(process.env.REACT_APP_BASE_URL+"/addEmployee",{
        empName: empName
        }).then((response)=>{
         console.log(response.data.affectedRows)
         FetchRecordHandler_when_data_changed();
        if(response.data.affectedRows){setEmployeeName("");}
        });
        




    


    }

return (

  <div className = "container">
      <div class="title_bar">Add new employee</div>
    <Card
  css_class={"card"}
  css_h1={"card"}
  text={"card_text"}
  >
    <h1 className="card_h1">Create Employee List.</h1>
    <form method="post" onSubmit={submitEmployeeData}>
        <label htmlfor="empName"  >Employee Name</label>
        <input type="text"  name="empName" value={empName} onChange={empNameHandler}></input>
        <Button type="submit" css_class={"save_btn"}>Save</Button>

    </form>
    {empNameEmpty && <h3>!! Please fill Employeee Name</h3>}
    </Card>

</div>


    );

}

export default EmployeeForm;