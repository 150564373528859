import React, { Fragment, useState } from "react";
// import "../components/elementStyle.css";
import Button from "../components/Button.js";
// import "./tool.css";

const Element = (props) => {
  const [currentObj, setObj] = useState(props.children);

  //  const {word,deleteWord,updateWord} = props;
  //console.log(props.recordId);
  const getUpdateId = () => {
    props.updateWord(currentObj);
  };

  const getDeleteId = () => {
    props.deleteWord(props.id);
  };

  const issue_tool = () => {
    props.issueTool(props);
  };

  const box_location = props.boxInfo.filter((bI) => {
    return bI.box_id === props.boxId;
  });
  const add_row = () => {
    props.addRow(props, box_location);
  };

 

//  const hlDiv = document.getElementsByClassName(props.hl);
// //  hlDiv.style.color="red";
 if(props.hl==props.recordId){

  return (
    <Fragment>
      
      <tr>
        <td id={props.recordId} className="red">{props.toolName}</td>
        <td className="red" >
          {box_location.length && box_location[0].box_id}
          <br />
          {box_location.length && box_location[0].extra}
        </td>
        <td className="red">{box_location.length && box_location[0].location}</td>
        <td className="red">{props.totalToolQut}</td>

        <td>
          <Button onClick={issue_tool}>Issue</Button>
          
        
        </td>
      </tr>
    </Fragment>
  );}else{
    return (
      <Fragment>
        
        <tr>
          <td id={props.recordId} className={props.hl}>{props.toolName}</td>
          <td className={props.hl}>
            {box_location.length && box_location[0].box_id}
            <br />
            {box_location.length && box_location[0].extra}
          </td>
          <td className={props.hl}>{box_location.length && box_location[0].location}</td>
          <td className={props.hl}>{props.totalToolQut}</td>
  
          <td>
            <Button onClick={issue_tool}>Issue</Button>
            
          
          </td>
        </tr>
      </Fragment>
    );

  }
};

export default Element;
