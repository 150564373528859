/**
 * this is submit button of all form pages
 */

import  React from 'react';
// import classes from './ui.module.css';

const Button = (props) => {

    return(
        <button className={props.css_class}>{props.children}</button>
    )
}

export default Button;
