import React from "react";

const SelectListTool = (props) =>{
    return(
<select onChange={props.selectedValueHandler}>
{props.boxList.map((ele)=>{
 return(<option value={ele.tool_name}>{` ${ele.tool_name}`}</option>)

})}
   
</select>
    )
}

export default SelectListTool;