import React,{useEffect, useState, useContext} from 'react';

import Button from '../../component/Button';
import Card from '../../component/Card';
import Axios from'axios';
import classes1 from '../../component/ui.module.css';
import classes from '../../component/style.module.css'
import MyContext from '../../store/MyData';
// import '../../component/form.css'
import AlertModal from '../../components/Modal/Modal.js'
 
const Addbox = () => {
const [box_id, setbox_id] = useState("");
const [location, setlocation] = useState("");
const [tool, settool] = useState("");
const [extra, setextra ] = useState('');
const [abc, setabc ] = useState(false)
const [xyz, setxyz ] = useState("")
const [box_idList, setbox_idList] = useState([]);
const [lastbox_id, setLastbox_id] = useState([]);
const [active, setActive] = React.useState(0);
const [submitResponse, setSubmitResponse] = useState([]);

const [boxIdEmpty, setBoxIdEmpty] = useState(false);
const [boxLocationEmpty, setBoxLocationEmpty] = useState(false);
const [showAlertModal, setShowAlertModal] = useState(false);
const [messageAlertModal, setMessageAlertModal] = useState("");
const myData = useContext(MyContext)



const FetchBoxRecordHandler = () => {
  

  // console.log(myData.boxRecord.length);
  if (myData.boxRecord.length === 0) {
    // console.log("call fetch in box list")
    Axios.get(process.env.REACT_APP_BASE_URL + "/boxList")
      .then((responce) => {
        if (responce.data) {
          console.log(responce.data);
          // setboxList(responce.data);
          myData.setBox(responce.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}; //end  function

useEffect(() => {
  FetchBoxRecordHandler();
}, []);


const FetchBoxRecordHandler_new_data_added = () => {
  

    // console.log("call fetch in box list")
    Axios.get(process.env.REACT_APP_BASE_URL + "/boxList")
      .then((responce) => {
        if (responce.data) {
          console.log(responce.data);
          // setboxList(responce.data);
          myData.setBox(responce.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
 
}; //end  function


/**
 * End load data
 */

const boxHandler = (event) => {
event.preventDefault();
setbox_id(event.target.value);
//console.log(box_id);
}

const locationHandler = (event) => {
event.preventDefault();
setlocation(event.target.value);
//console.log(location);
}


const toolHandler = (event) =>{
  settool(event.target.value)
}

const extraHandler = (event) =>{
  setextra(event.target.value)
}

const moreHandler = (event) =>{
  setabc(event.target.value)
}

const xyzHandler = (event) =>{
 // console.log("xyzHandler");
setxyz(event.target.value);

}

const submitHandler = (event) => {
  event.preventDefault();
  //console.log("Box data");
    /**
     * form validaton
     */
     if (box_id.trim() === "") {
      //  alert("Please Select Employee");
      setBoxIdEmpty(true);
      return;
    }
    setBoxIdEmpty(false);

    if (location.trim() === "") {
      //  alert("Please Select Employee");
      setBoxLocationEmpty(true);
      return;
    }
    setBoxLocationEmpty(false);

  
  
  Axios.post(process.env.REACT_APP_BASE_URL+"/addboxdata",{
box_id: box_id,
location: location,
tool: tool,
extra: extra,
abc: abc,
xyz: xyz
}).then((response)=>{
  FetchBoxRecordHandler_new_data_added();
  //console.log(response.data.affectedRows)
  console.log(response.data.code);
  console.log(response.data.sqlMessage);
  if(response.data.code==="ER_DUP_ENTRY"){
    setMessageAlertModal(`Box No. ${box_id}  already exists, Please fill another Box No.`);
    setShowAlertModal(true);
  }
  setbox_id('');
  setlocation('');
  settool('')
  setextra('')
//setSubmitResponse(response.data.affectedRows);
setSubmitResponse(response.data);
});


}
const onCloseAlertModal = () =>{
  setShowAlertModal(false);
}

   
    return (
      <div className = "container">
        {showAlertModal && <AlertModal onClick={onCloseAlertModal} message={messageAlertModal}/>} 
        <div class="title_bar">Add new box</div>
  <Card
  css_class={"card"}
  css_h1={"card"}
  text={"card_text"}
  >
    <h1 className="card_h1" >Create Box List.</h1>
<form method="post" onSubmit={submitHandler}>
    <label htmlFor="box_id">Box No.</label>
    <input type="text" name="boxId" value={box_id} onChange={boxHandler}></input>
    <label htmlFor="meaning" >Location</label>
    <input type="text" name="Location" value={location} onChange={locationHandler}></input>

    <label htmlFor="meaning" >Description</label>
    <input type="text" name="extra" value={extra} onChange={extraHandler}></input>
   

  
    <div className="radio_group">
    
   
    <Button type="submit" css_class={"save_btn"}>Save</Button>
   </div>
</form>

 


{boxIdEmpty && <h3>!! Please fill Box No.</h3>}
  {boxLocationEmpty && <h3>!! Please fill Box Location</h3>}


  </Card>
  

  {lastbox_id.map((ele)=>{
return ( <div>
 <div> { ele.box_id } </div>
 <div> { ele.meaning } </div>
 <div> { ele.similar } </div>
 <div> { ele.sentence } </div>
 <div> { ele.box_id } </div>
  </div>);
  })}

 </div>
    );
} 

export default Addbox;