import { useContext, useEffect, Fragment } from "react";
import ToolContext from "../../context/toolContext";
import SearchBox from "../../components/SearchBox.js";
import SearchByEmp from "../../components/SearchByEmp";
import SearchToolByBox from "../../components/SearchToolByBox";
import PageContext from "../../store/PageContext";
import Table from "./Table";

import Containers from "../components/Container";

const ToolOnSite = () => {
  const {
    toolsOnSite,
    fetchToolOnSite,
    fetchToolOnSiteByEmp,
    fetchToolOnSiteByBoxId,
    searchToolOnSite,
    employees,
    fetchEmployees,
    boxs,
    fetchBoxs,
  } = useContext(ToolContext);

  const pageContext = useContext(PageContext);

  useEffect(() => {
    fetchBoxs();
    fetchEmployees();
    fetchToolOnSite();
    pageContext.setCurrentPage("toolsOnSite");
  }, []);

  return (
    <Fragment>
      <Containers>
        <div className="bg__color">
          <div className="elign__element__right">
            <div className="flex-items">
              <SearchBox
                placeholder="Search"
                value="Doe"
                searchHanler={searchToolOnSite}
              />
            </div>
            <div className="flex-items">
              <SearchByEmp
                employees={employees}
                fetchToolOnSiteByEmp={fetchToolOnSiteByEmp}
              ></SearchByEmp>
            </div>

            <div className="flex-items">
              <SearchToolByBox
                boxs={boxs}
                fetchToolByBoxId={fetchToolOnSiteByBoxId}
              />
            </div>
            <div className="flex-items color-black">
              <label>
                {/* <input
              type="checkbox"
              id="allRecord"
              name="allRecord"
              value="allRecord"
              checked={isChecked}
              onChange={handleOnChange}
            /> */}
                &nbsp;&nbsp;<b className="color-black">Returned Tools</b>
              </label>
            </div>
          </div>
        </div>
        <div class="title_bar">Tools on working site</div>

        <Table toolsOnSite={toolsOnSite} />
      </Containers>
    </Fragment>
  );
};

export default ToolOnSite;
