import React from "react";
import Axios from "axios";

const Test = () =>{

    const test1 = () =>{

        
        Axios.get("https://gstadeveloper.com/vocabulary_server_url/test")
        .then((responce) => {
           console.log(responce);
          if (responce) {
            // setToolissueRecord(responce.data);
           // myData.setToolIssue(responce.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
  
    
 




    }

    const test2 = () => {
        
        Axios.get("https://gstadeveloper.com/cms_url/test")
        .then((responce) => {
           console.log(responce);
          if (responce) {
            // setToolissueRecord(responce.data);
        //    myData.setToolIssue(responce.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
  
   
    }

    const test3 = () => {
       
        Axios.get("https://gstadeveloper.com/cms_url/test")
        .then((responce) => {
           console.log(responce);
          if (responce) {
            // setToolissueRecord(responce.data);
         //   myData.setToolIssue(responce.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
  
      

    }

return (

<div>
   <h3>This is test file</h3> 
   <button onClick={test1}>test1</button>
   <button onClick={test2}>test2</button>
   <button onClick={test3}>test3</button>
</div>


)


}


export default Test;