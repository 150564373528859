import React from 'react';

const BoxDropdownMenu = (props) =>{
   //console.log(props.boxData)
    return (<div>
<select onChange={props.boxSelecteNoHandler}>
   
    {props.boxData.map((box)=>{
      return(<option value={box.box_id} >{`Box ${ box.box_id} - ${ box.extra} `}</option>);

    })}


</select>

    </div>);
}


export default BoxDropdownMenu;