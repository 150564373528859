import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import Card from "../../component/Card";
import Button from '../../component/Button';
import MyContext from "../../store/MyData";
import Axios from "axios";
import SelectOption from "../tool-on-site/SelectOption";
import SelectListBox from "./SelectListBox";

import { useParams } from "react-router";

const AddToolissue = () => {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth();
  const year = currentDate.getFullYear();
  const fullDate = year + "-" + month + "-" + day;

  const [boxId, setBoxId] = useState(0);
  const [box_id, setbox_id] = useState(1);
  const [toolName, setToolName] = useState("");

  const [toolQut, setToolQut] = useState("");
  const [toolQutTotal, setToolQutTotal] = useState("");
  const [empName, setEmpName] = useState("");
  const [issueDate, setIssueDate] = useState(fullDate);
  /* form validation */
  const [empNameEmpty, setEmpNameEmpty] = useState(false);
  const [enterToolQutEmpty, setEnterToolQutEmpty] = useState(false);
  const [enterIssueQutyOver, setEnterIssueQutyOver] = useState(false);
  const [enterIssueQutyLow, setEnterIssueQutyLow] = useState(false);

  const myData = useContext(MyContext);
  const { issue_box_id, issue_tool_name, qut_max, box_record_Id } = useParams();

  const navigate = useNavigate();
  const FetchRecordHandler = () => {
    if (myData.empRecord.length === 0) {
      Axios.get(process.env.REACT_APP_BASE_URL + "/emplist")
        .then((responce) => {
          if (responce) {
            myData.setEmpRecordF(responce.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } // end if condition
  }; //end  function

  useEffect(() => {
    FetchRecordHandler();

    setBoxId(issue_box_id);
    setToolName(issue_tool_name);
    // setEmpName(qut_max);

   // setToolQutTotal(qut_max);
    setToolQut(qut_max);
  }, []);

  // const boxIdHandler = (e) => {
  //   setBoxId(e.target.value);
  // };

  /**
   * End loading data
   *
   */
  /**
   *
   * issue tool data saving
   */
  const toolNameHandler = (e) => {
    setToolName(e.target.value);
  };

  const empNameHandler = (e) => {
    setEmpName(e.target.value);
  };

  const toolQutHandler = (e) => {
    const tq = e.target.value;
    setToolQut(tq);
  };

  const dateChangeHandler = (e) => {
    setIssueDate(e.target.value);
    // console.log(e.target.value);
  };

  /**
   *  updating boxtool table , issued tool table record in app
   */
  const FetchBoxToolRecordHandler = () => {
    console.log("fetching data");
    Axios.get(process.env.REACT_APP_BASE_URL + "/boxtoolList")
      .then((responce) => {
        // console.log(responce.data);
        if (responce) {
          myData.setBoxTool(responce.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }; //end  function

  const FetchToolIssueRecordHandler = () => {
    //updating array of issuetool record
    Axios.get(process.env.REACT_APP_BASE_URL + "/toolissuerecord")
      .then((responce) => {
        //     console.log(responce);
        if (responce) {
          // setToolissueRecord(responce.data);
          myData.setToolIssue(responce.data);
          console.log(responce.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }; //end  function

  const toolIssuedSuccesfullyHandeler = (boxid) => {
    // const data ={
    //    issueing_tool_box_id:boxid
    //  }

    navigate("/boxtooleditor/" + boxid);
  }; // end function

  //console.log("box rexord id" + box_record_Id);
  const updateBoxToolHandler = (e) => {
    myData.setRowHilightFK(0);
    e.preventDefault();
    console.log("in update tool box");
    /**
     * form validaton
     */
    // if (empName.trim() === "") {
    //   //  alert("Please Select Employee");
    //   setEmpNameEmpty(true);
    //   return;
    // }
    setEmpNameEmpty(false);

    if (toolQut.trim() === "") {
      setEnterToolQutEmpty(true);
      return;
    }
    setEnterToolQutEmpty(false);

    // const toolQutTotalI = +toolQutTotal;
    // const toolQutI = +toolQut;

    // if (toolQutI > toolQutTotalI) {
    //   //  alert("Please Select Employee");
    //   setEnterIssueQutyOver(true);
    //   return;
    // }
    setEnterIssueQutyOver(false);

    // if (toolQutI === 0 || toolQutI < 0) {
    //   //  alert("Please Select Employee");
    //   setEnterIssueQutyLow(true);
    //   return;
    // }
    setEnterIssueQutyLow(false);

    /**
     * End form validation
     */

    Axios.post(process.env.REACT_APP_BASE_URL + "/update-toolQut-in-box", {
      boxId: boxId,
      toolName: toolName,
      toolQut: toolQut,
      // toolQutTotal: toolQutTotal,
      box_record_Id: box_record_Id,
      // empName: empName,
      // issueDate: issueDate,
    }).then((response) => {
      if (response.data.affectedRows) {
        setBoxId("");
        setToolName("");
        setEmpName("");
        const box_record_IdI = parseInt(box_record_Id);
        myData.setRowHilightFK(box_record_IdI);
        setIssueDate(fullDate);

        // updating arrays
        FetchBoxToolRecordHandler();
        FetchToolIssueRecordHandler();
        // navigate to BoxTool record Table
        console.log("updated");
        toolIssuedSuccesfullyHandeler(boxId);
      }
    });
  };

  const boxHandler = (event) => {
    event.preventDefault();
    setBoxId(event.target.value);
    console.log(event.target.value);
  };

  return (
    <div className = "container">
      <Card
 css_class={"card"}
 css_h1={"card"}
 text={"card_text"}
 >
       <h1 className="card_h1">Update Box data</h1>
        <form method="post" onSubmit={updateBoxToolHandler}>
          
          <br />

          <label>
            Tool Q{" "}
            <input
              type="text"
              name="toolQut"
              value={toolQut}
              onChange={toolQutHandler}
            ></input>
          </label>
          {/* <div>
            <label>Date</label>
            <input
              type="date"
              min="08-19-2022"
              max="01-01-2027"
              onChange={dateChangeHandler}
            ></input>
          </div> */}

          <label>
            Tool Name{" "}
            <input
              type="text"
              name="toolName"
              value={toolName}
              onChange={toolNameHandler}
              // readOnly={true}
            ></input>
          </label>

          {/* <label>
            Total Tool Qut{" "}
            <input type="text" name="toolQutTotal" value={toolQutTotal}></input>
          </label> */}

          <label>
            Box No{" "}
            <input
              type="number"
              name="box_id"
              value={boxId}
              // onChange={boxIdHandler}
              readOnly={true}
            ></input>
          </label><div>
            <SelectListBox
              selectedValueHandler={boxHandler}
              boxList={myData.boxRecord}
            ></SelectListBox>
          </div>

          <Button type="submit" css_class={"save_btn"}>
              Save
            </Button>
        </form>
     
      {enterToolQutEmpty && <h3>!! Please fill Quantity Issued</h3>}
      {enterIssueQutyOver && (
        <h3>!! Quantity Issued can't be more than Total Quantity</h3>
      )}
      {empNameEmpty && <h3>!! Please select Employeee Name</h3>}
      {enterIssueQutyLow && (
        <h3>!! Quantity Issued can't be zero or Less than Zero</h3>
      )}
    </Card>
    </div>
  );
};

export default AddToolissue;
