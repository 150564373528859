import Classes from "./containers.module.css";

const Containers = (props) =>{

    return (<div className={Classes.containers}>

{props.children}
    </div>);
}

export default Containers;