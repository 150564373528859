import React from "react";

const SearchToolByBox = ({fetchToolByBoxId, boxs}) =>{
//console.log(props.boxRecord);
    return(
    <select onChange={(e)=>{ fetchToolByBoxId(e.target.value)}}>
       {/* <option >Box 0</option> */}
        {boxs.map((box)=>{
return(
<option key={box.box_id}  value={box.box_id}>{`Box ${box.box_id} - ${box.extra}`}</option>
);

        })}
     
    </select>
    
        )
}

export default SearchToolByBox;