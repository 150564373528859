import React,{useEffect, useState,useContext} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Button from '../../component/Button';
import Card from '../../component/Card';
import Axios from'axios';
import classes1 from '../../component/ui.module.css';
import classes from '../../component/style.module.css'
// import '../../component/form.css'
import SelectListBox from './SelectListBox';
import SelectListTool from './SelectListTool';
import MyData from '../../store/MyData'


const AddBoxtoolsInEditerDirect = () => {
const [box_id, setbox_id] = useState("1");
const [location, setlocation] = useState("");
const [tool, settool] = useState("");
const [qut, setQut ] = useState(1);
const [abc, setabc ] = useState(false)
const [xyz, setxyz ] = useState("")

const [lastbox_id, setLastbox_id] = useState([]);
const [active, setActive] = React.useState(0);
const [submitResponse, setSubmitResponse] = useState([]);

const [box_idList, setbox_idList] = useState([]);
const [toolNameList, setToolNameList] = useState([]);
const [boxToolEmpty, setBoxToolEmpty] = useState(false);
const [extra, setextra ] = useState('');
const myData = useContext(MyData);

const navigate = useNavigate();

const FetchRecordHandler = () => {



//console.log(myData.boxRecord.length);
if(myData.boxRecord.length===0){
  //console.log("call fetch in box list")
  Axios
  .get(process.env.REACT_APP_BASE_URL + "/boxList")
  .then((responce) => {
    if (responce.data) {
   // console.log(responce.data);
   // setboxList(responce.data);
    myData.setBox(responce.data);
   
    }
  })
  .catch((err) => {
    console.log(err);
  });

}

//console.log(myData.toolissueRecord.length);
if(myData.toolissueRecord.length===0){

  Axios
  .get(process.env.REACT_APP_BASE_URL + "/toolissuerecord")
  .then((responce) => {
    // console.log(responce.data);
    if (responce) {
     // setToolissueRecord(responce.data);
      myData.setToolIssue(responce.data);
    }
  })
  .catch((err) => {
    console.log(err);
  });


}// end if condition


};//end  function

useEffect(() => {
  FetchRecordHandler(); 
  }, []);



  

const FetchBoxRecordHandler =  () => {
 
  Axios
  .get(process.env.REACT_APP_BASE_URL + "/toollistrecord")
  .then((responce) => {
    if (responce.data) {
   
   // setboxList(responce.data);
    myData.setToolList(responce.data);
   
    }
  })
  .catch((err) => {
    console.log(err);
  });


};//end  function


useEffect(() => {
  
FetchBoxRecordHandler();

}, []);


 

/**
 * END data load 
 */

const boxHandler = (event) => {
event.preventDefault();
setbox_id(event.target.value);
console.log(event.target.value);
}

const toolListHandler = (event) => {
  event.preventDefault();
  settool(event.target.value);
  console.log(event.target.value);
 }




const qutHandler = (event) =>{
  setQut(event.target.value)
}



const submitHandler = (event) => {
  event.preventDefault();
  

  if (tool.trim() === "") {
   
    setBoxToolEmpty(true);
    return;
  }
  setBoxToolEmpty(false);

  Axios.post(process.env.REACT_APP_BASE_URL + "/addboxtoolsdirect", {
    box_id: box_id,
    tool: tool,
    qut: qut,
    extra: extra,
    }).then((response) => {
  //  console.log(response.data.length);
    if (response.data.length > 0) {
      alert("Tool is already exist in Box");
    //  navigate("/boxtooleditor/" + box_id);
    }
    //  setbox_id('');
    setlocation("");
    //  settool('')
    setQut(1);
    //setSubmitResponse(response.data.affectedRows);
    setSubmitResponse(response.data);
    myData.setRowHilightFK(response.data.insertId);
    myData.FetchBoxToolRecord();
    FetchBoxRecordHandler();
    navigate("/boxtooleditor/" + box_id);
  });
};



const extraHandler = (event) =>{
  setextra(event.target.value)
}
   
    return (
      <div className = "container">
          <div class="title_bar">Add tools to box</div>
      <Card
 css_class={"card"}
 css_h1={"card"}
 text={"card_text"}
 >
       <h1 className="card_h1">Add Tool to box</h1>
    <form method="post" onSubmit={submitHandler}>
        <div>
<SelectListBox 
selectedValueHandler={boxHandler}
boxList={myData.boxRecord}

></SelectListBox>

</div>
        <div>
            <label htmlFor="toolName">Tool Name</label>
            <input
              type="text"
              name="qut"
              className="tb1"
              onChange={toolListHandler}
            ></input>
          </div>

          <div>
            <label htmlFor="quantity">Quantity</label>
            <input
              type="text"
              name="qut"
              className="tb1"
              value={qut}
              onChange={qutHandler}
            ></input>
          </div>

          <label htmlFor="meaning" >Description</label>
    <input type="text" name="setnece" value={extra} onChange={extraHandler}></input>
   

          {/* <div>
            <label htmlFor="box_no">Box No.</label>
            <input type="text" value={box_id} readOnly={true}></input>
          </div> */}

          <div className="radio_group">
            <Button type="submit" css_class={"save_btn"}>
              Save
            </Button>
          </div>

          <div>
            {/* <SelectListTool 
selectedValueHandler={toolListHandler}
boxList={myData.toolListVar}

></SelectListTool> */}
            {/* <label htmlFor="box_id">Box Id</label> */}
            {/* <input type="text" name="boxId" value={box_id} onChange={boxHandler}></input> */}

            {/* <SelectListBox 
selectedValueHandler={boxHandler}
boxList={myData.boxRecord}

></SelectListBox> */}
          </div>
        </form>

 



 {/* {boxIdEmpty && <h3>!! Please fill Box No.</h3>} */}
     {boxToolEmpty && <h3>!! Please Select Tool Name</h3>}


  </Card>
  

  {lastbox_id.map((ele)=>{
return ( <div>
 <div> { ele.box_id } </div>
 <div> { ele.meaning } </div>
 <div> { ele.similar } </div>
 <div> { ele.sentence } </div>
 <div> { ele.box_id } </div>
  </div>);
  })}

 </div>
    );
} 

export default AddBoxtoolsInEditerDirect;