import React, { Component } from 'react';
import axios from 'axios';
export default class FilesUploadComponent extends Component {


    constructor(props) {
        super(props);
        this.onFileChange = this.onFileChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            selectedFile:'',
        }
    }
    onFileChange(e) {
        this.setState({ selectedFile: e.target.files[0] })
    }


    
    onSubmit(e) {
        console.log("submited");
        e.preventDefault()
        const formData = new FormData()
       // data.append('file', this.state.selectedFile)
        formData.append('file', this.state.selectedFile)
        console.log(this.state.selectedFile);
        axios.post(process.env.REACT_APP_BASE_URL + "/upload", formData, {      }).then(res => {
            console.log(res)
        })
    }


    render() {
        return (
            <div className="container">
                <div className="row">
                    <form method="post" onSubmit={this.onSubmit}>
                        <h3>React File Upload</h3>
                        <div className="form-group">
                            <input type="file" className="form-control" name="upload_file" onChange={this.onFileChange} />
                        </div>
                        <div className="form-group">
                            <button  type="submit">Upload</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
