import React, { useState } from "react";
import Modal from "./Modal";
import Button from "./Button";
import './delete_updateForm.css'

const Alert = (props) =>{

    const canselDelete = () =>{
     props.AlerthideModalHandler(false);
    }

    const yesDelete = () =>{
     props.AlerthideModalHandler(true) 
    }

    return (
<Modal>
<h3>Do you want to delete !!</h3>


<div className="button__container">
<Button onClick={yesDelete}>Yes</Button>
<Button onClick={canselDelete}>Cancel</Button>
</div>

</Modal>


    );
}

export default Alert;