import Button from "../../components/Button/Button";
import { FaTrashAlt } from 'react-icons/fa';




const ToolOnSiteRow = ({ toolOnSite, trashTool_ToolOnSite, handlerReturnToll }) => {
  
  const { tool_name, tool_qut, emp_name, box_id, issue_date  } = toolOnSite;

  const d = new Date(issue_date);
  const day = d.getUTCDate();
  const month = d.getUTCMonth();
  const year = d.getUTCFullYear();



  return (
    <tr>
      <td>{tool_name}</td>
      <td>{tool_qut}</td>
      <td>{emp_name}</td>
      <td>{box_id}</td>
      <td>
        {day}-{month}-{year}
      </td>
      <td>
        <Button onClick={()=>{handlerReturnToll(toolOnSite)}}>Return</Button>
        <Button onClick={(key)=>{trashTool_ToolOnSite(toolOnSite.id,toolOnSite.tool_qut)}}><FaTrashAlt /></Button>
      </td>
    </tr>
  );
};

export default ToolOnSiteRow;
