import React, { useState, useEffect } from "react";
import axios from "axios";

const MyContext = React.createContext({
  boxToolRecord:[],
  
  toolListVar:[],
  
  toolissueRecord: [],
  empRecord: [],
  boxRecord: [],
  boxRecordUnique: [],
  rowHilightK: 0,
  
  addToolRecord: () => {},
  FetchToolListRecord: () => {},
  FetchEmpRecord: () => {},
  FetchTooIssueRecord: () => {},
  FetchBoxRecord: () => {},
  FetchBoxToolRecord: () => {},
  FetchBoxRecord_: (c) => {},

  setToolList:()=>{},
  setBox:()=>{},
  setBoxTool:(value)=>{},
  setToolIssue:(value)=>{},
  setEmpRecordF:(value)=>{},
  setRowHilightFK:(value)=>{}
});

export const DataProvider = (props) => {

  //console.log("data store");
  //const [toolRecordF, setToolRecord] = useState([]);
  const [empRecordF, setEmpRecordF] = useState([]);
  const [toolIssueRecordF, setToolIssueRecordF] = useState([]);
  const [boxRecordF, setBoxRecordF] = useState([]);
 

  const [boxToolRecordF, setBoxToolRecordF] = useState([]);
  const [toolListVarUS, setToolListVarUS] = useState([]);
  const [rowHilightV, setRowHilightFV] = useState(0);

  const AddToolRecordHandler = () => {};
//console.log("toollistrecord");

  const FetchToolListRecordHandler =() =>{
  
    axios.get(process.env.REACT_APP_BASE_URL+"/toollistrecord")
         .then((response)=>{
          setToolListVarUS(response.data);
         })
         .catch((err)=>{
          console.log(err);
         })

  }

  const FetchEmpRecordHandler = () => {
    // useEffect(()=>{
  //    console.log("Emp record");
    axios
      .get(process.env.REACT_APP_BASE_URL + "/emplist")
      .then((response) => {
        setEmpRecordF(response.data);
        //   console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    //},[])
  };

  const FetchTooIssueRecordHandler = () => {
//console.log("tool issue record")

    axios
      .get(process.env.REACT_APP_BASE_URL + "/toolissuerecord")
      .then((responce) => {
        // console.log(responce.data);
        if (responce) {
          setToolIssueRecordF(responce.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * box record
   */

   const FetchBoxRecordHandler = () => {
   
    axios
      .get(process.env.REACT_APP_BASE_URL + "/boxList")
      .then((responce) => {
        if (responce.data) {
        console.log(responce.data);
          setBoxRecordF(responce.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });


   
  };


  /**
   * tool record
   */
  const FetchBoxToolRecordHandler = () => {
   
    axios
      .get(process.env.REACT_APP_BASE_URL + "/boxtoolList")
      .then((responce) => {
        if (responce.data) {
       //   console.log("test");
       //   console.log(responce.data);
          setBoxToolRecordF(responce.data);
//[...responce.data.box_id]
               
        }
      })
      .catch((err) => {
        console.log(err);
      });


   
  };




  const FetchBoxRecordHandler_ =  (c) => {
  console.log("called in context")
  console.log(c)
   
    console.log(boxRecordF)
  };







  return (
    <MyContext.Provider
      value={{
        boxToolRecord: boxToolRecordF,
        toolListVar: toolListVarUS,
        toolissueRecord: toolIssueRecordF,
        empRecord: empRecordF,
        boxRecord: boxRecordF,
        rowHilightK: rowHilightV,
     
        addToolRecord: AddToolRecordHandler,
        FetchToolListRecord: FetchToolListRecordHandler,
        FetchEmpRecord: FetchEmpRecordHandler,

        FetchTooIssueRecord: FetchTooIssueRecordHandler,
        FetchBoxRecord: FetchBoxRecordHandler,
        FetchBoxToolRecord: FetchBoxToolRecordHandler,
        FetchBoxRecord_: FetchBoxRecordHandler_,

        setToolList:setToolListVarUS,
        setBox:setBoxRecordF,
        setBoxTool:setBoxToolRecordF,
        setToolIssue:setToolIssueRecordF,
        setEmpRecordF:setEmpRecordF,
        setRowHilightFK:setRowHilightFV,

        
        
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

export default MyContext;
