import { Fragment, useContext } from 'react';
import { useNavigate } from "react-router-dom";


import { GiHamburgerMenu } from 'react-icons/gi';

import classes from './Header.module.css';
import MyData from "../../store/MyData";
import PageContext from "../../store/PageContext";
const Header = (props) => {

 const navigate = useNavigate();
 const myData = useContext(MyData);
 const pageContext = useContext(PageContext);


 const navegateToIssueTool = () =>{

   navigate("/");
 }

 const navegateToBoxTool = () =>{
navigate("/boxtool/1")
 }
 const navegateEditTool = () =>{
  navigate("/boxtooleditor/1");
 }

 
const p=pageContext.currentPage;


  return (
    <Fragment>
     <div className={classes.containers}>
      <header className={classes.header}> 
     
<div className={classes.header_items}>{(p==="boxtool")&&<button className="header_button_pressed" onClick={navegateToBoxTool}>My tools</button>}{!(p==="boxtool")&&<button className="header_button" onClick={navegateToBoxTool}>My tools</button>} </div>
{/* <div className={classes.header_items}> <button  className="header_button" onClick={navegateToBoxTool}>My tools</button></div> */}
<div className={classes.header_items}>{(p==="toolsOnSite")&&<button className="header_button_pressed" onClick={navegateToIssueTool}>On Site</button>}{!(p==="toolsOnSite")&&<button className="header_button" onClick={navegateToIssueTool}>On Site</button>} </div>
{/* <div className={classes.header_items}><button className="header_button" onClick={navegateEditTool}>Edit</button></div> */}
<div className={classes.header_items}>{(p==="boxtoolEditor")&&<button className="header_button_pressed" onClick={navegateEditTool}>Edit</button>}{!(p==="boxtoolEditor")&&<button className="header_button" onClick={navegateEditTool}>Edit</button>} </div>



<div className={classes.header_items}><span className={classes.icon_size}>  <GiHamburgerMenu  onClick={props.onShowSideBar} /></span></div>

      </header>
      {/* <div className={classes['main-image']}>
     
      </div> */}
      </div>
    </Fragment>
  );
};

export default Header;
