import { createContext, useState } from "react";

const PageContext = createContext(null);

export const PageValueProvider = (props) => {
  const [currentPageInProvider, setCurrentPageInProvider] = useState(null);

  return (
    <PageContext.Provider
      value={{
        currentPage: currentPageInProvider,
        setCurrentPage: setCurrentPageInProvider,
      }}
    >
      {props.children}
    </PageContext.Provider>
  );
};

export default PageContext;