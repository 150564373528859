import { useContext } from "react";
import { Link, useNavigate, useParams, Route, Routes } from "react-router-dom";

import Modal from "../UI/Modal";

import classes from "./sidebar.module.css";
import CartContext from "../../store-sidebar/cart-context";

const Cart = (props) => {
  const cartCtx = useContext(CartContext);

  const cartItemRemoveHandler = (id) => {
    cartCtx.removeItem(id);
  };

  return (
    <Modal onClose={props.onClose}>
      <div className={classes.sidbar_container}>
        <div className={classes.actions}>
          <button className={classes["button--alt"]} onClick={props.onClose}>
            X
          </button>
        </div>

      
        <h3>Edit data</h3>
      
        <div className={classes.link_box}>
          {" "}
          <Link className={classes.link} to="/employee">
           Edit Employee Detail
          </Link>
        </div>
        <div className={classes.link_box}>
          <Link className={classes.link} to="/box">
           View Box Detail
          </Link>
        </div>
        <div className={classes.link_box}>
          <Link className={classes.link} to="/toollist">
           Edit Tools Detail
          </Link>
        </div>
        <h3>Add new data</h3>
      

        {/* <div className={classes.link_box}><Link     className={classes.link} to="addboxtools">Add tool to box</Link></div> */}

        <div className={classes.link_box}>
          {" "}
          <Link className={classes.link} to="/employeeform">
            Create Employee List
          </Link>
        </div>
        <div className={classes.link_box}>
          <Link className={classes.link} to="/addbox">
            Create Box List
          </Link>
        </div>
        <div className={classes.link_box}>
          <Link className={classes.link} to="addtoollist">
            Create Tools List
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default Cart;
