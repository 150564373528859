import React from "react";

const SelectListBox = (props) =>{
    return(
<select onChange={props.selectedValueHandler}>
    <option>Box No  - Location - Desc</option>
{props.boxList.map((ele)=>{
 return(<option value={ele.box_id}>{`Box No ${ele.box_id} - ${ele.location} - ${ele.extra}`}</option>)

})}
   
</select>
    )
}

export default SelectListBox;