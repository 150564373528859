import React from "react";


const SelectOption = (props) => {
//console.log(props.listData);
return(
<select onChange={props.getSelectedValue}>
<option >Select Emp</option>
    {props.listData.map((el)=>{

return (<option value={el.emp_name}>{el.emp_name}</option>)

    })}

</select>
);
}

export default SelectOption;