import React, { Fragment, useState } from "react";
// import "./elementStyle.css";
import Button from "../components/Button.js";

const Element = (props) => {
  const [currentObj, setObj] = useState(props.children);

  //  const {word,deleteWord,updateWord} = props;

  const getUpdateId = () => {
    props.updateWord(currentObj);
  };

  const getDeleteId = () => {
    props.deleteBoxRecord(props.id);
  };

  const updateBox_data = () =>{
      props.updateBox(props)
  }
  return (<Fragment>
    <tr>
      <td>
    <span class="title"> {props.children.box_id}</span>
          </td>
          <td>
            <span class="ex"> {props.children.location}</span>
          </td>
          
          <td>
            <span class="ex"> {props.children.extra}</span>
          </td>
        
        <td class="right">
          <Button onClick={updateBox_data} >update</Button>
          {/* <Button onClick={getUpdateId}>update</Button> */}
          <Button onClick={getDeleteId}>delete</Button>
        </td>
     
      </tr>
      </Fragment>
  );
};

export default Element;
