import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import classes from './Modal.module.css';

const Backdrop = ({onClose}) => {
    return <div className={classes.backdrop} onClick={onClose}/>;
  };
  
  const ModalOverlay = (props) => {
    return (
      <div className={classes.modal}>
        <div className={classes.content}>{props.children}</div>
      </div>
    );
  };

const Modal = ({onClick,message}) =>{
return  ReactDOM.createPortal(
<Fragment>
     <Backdrop onClick={onClick} />
    <ModalOverlay><div>{message}</div> <div className={classes.justify_end}> <button onClick={onClick}>Ok</button></div> 
    </ModalOverlay>

 </Fragment>   
    ,document.querySelector('.modal-container')
)


}

export default Modal;