import React, { Fragment, useState } from "react";
// import "../components/elementStyle.css";
import Button from "../components/Button.js";
// import "./tool.css";

const Element = (props) => {
  const [currentObj, setObj] = useState(props.children);

  //  const {word,deleteWord,updateWord} = props;

  

  const getDeleteId = () => {
    props.deleteBoxToolRecord(props.id);
  };

const update_toolBox = () =>{
  props.updateBooXTool(props);
}

const box_location = props.boxInfo.filter((bI)=>{
  return bI.box_id===props.boxId;
});


if(props.hl==props.recordId){
  return (
    <Fragment>
      <tr>
        
      <td id={props.recordId} className="red">{props.toolName}</td>
        <td className="red">
          {box_location.length && box_location[0].box_id}
          <br />
          {box_location.length && box_location[0].extra}
        </td>
        <td className="red">{box_location.length && box_location[0].location}</td>
        <td className="red">{props.totalToolQut}</td>

        <td>
       
           
            <Button onClick={update_toolBox} >Update</Button>
            <Button onClick={getDeleteId}>delete</Button>
        </td>
      </tr>
    </Fragment>
  );
}else{
  return (
    <Fragment>
      <tr>
        
      <td id={props.recordId}>{props.toolName}</td>
        <td>
          {box_location.length && box_location[0].box_id}
          <br />
          {box_location.length && box_location[0].extra}
        </td>
        <td>{box_location.length && box_location[0].location}</td>
        <td>{props.totalToolQut}</td>

        <td>
       
           
            <Button onClick={update_toolBox} >Update</Button>
            <Button onClick={getDeleteId}>delete</Button>
        </td>
      </tr>
    </Fragment>
  );
}
};

export default Element;
