import React, { Fragment } from "react";
import Button from "../components/Button.js";
// import "../components/elementStyle.css";

const EmployeeList = (props) =>{

function  delete_emp(){

    props.deleteEmp(props.id)
  }

    return (<Fragment>
       
              <tr><td><span class="title"> {props.id}</span></td>
               
             <td> <span class="ex"> {props.name}</span></td>
              <td>
              <div class="right">
              {/* <Button onClick={updateWord} >update</Button> also works  */}
              {/* <Button >update</Button> */}
              <Button onClick={delete_emp}>delete</Button>
            </div>
                </td> 
             </tr>
              
         
           
            </Fragment>
      );
}

export default EmployeeList;