import React, { Fragment, useState } from "react";
// import "../components/elementStyle.css";
import Button from "../components/Button.js";

const Element = (props) => {
  const [currentObj, setObj] = useState(props.children);

  //  const {word,deleteWord,updateWord} = props;

  const getUpdateId = () => {
    props.updateWord(currentObj);
  };

  

  const deleteRow = () =>{
    props.deleteTool(props.id);
  }

  return (
    <Fragment>
      <tr>
        <td>{props.children.tool_name}</td>
       
        <td>{props.children.extra}</td>
        <td><Button onClick={deleteRow}>Delete</Button></td>
      </tr>
    </Fragment>
  );
};

export default Element;
