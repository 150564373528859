import React, {useState} from "react";
import Modal from "./Modal";
import Button from "./Button";
import './delete_updateForm.css'
import Axios from'axios';

const UpdateForm = (props) =>{
// const {cr} = props;
//console.log(props);
console.log("in updateForm, repeate call after every key stork")
/* cature form data */
const [updateId, setUpdateId] = useState(props.box_id);
const [boxLocation, setboxLocation] = useState(props.boxLocation);
const [boxDescription, setBoxDescription] = useState(props.boxDescription);




const boxLocationHandler = (event) => {
    event.preventDefault();
    setboxLocation(event.target.value);
  //  console.log(event.target.value);
    }
    
    const boxDescriptionHandler = (event) => {
    event.preventDefault();
    setBoxDescription(event.target.value);
    console.log(event.target.value);
    }
    
   
    
    
    
   
    
  
    /*END cature form data */

const updateRecord = (event) =>{

    event.preventDefault();
    //Axios.post("https://gstadeveloper.com/vocabulary_server_url/create",{
      Axios.put(process.env.REACT_APP_BASE_URL+`/update-box/${updateId}`,{
    box_id: updateId,
    boxLocation: boxLocation,
    extra: boxDescription
   
    }).then((response)=>{
      console.log(response)
     props.refreshForm(response);
    });


    props.hindUpdateBox_submit();
} 

//console.log("get objec value "+cr.id)
    return (
<Modal>

<div className="update__form">
<div className="button__container">
<Button onClick={updateRecord}>Submit</Button>
<Button onClick={props.hindUpdateBox_submit}>Cancel</Button>
</div>
    <h3>Modify box data</h3>
    <label htmlFor="box">Box Location</label>
    <input type="text" name="box" onChange={boxLocationHandler} defaultValue={boxLocation} ></input>

    <label htmlFor="box description">Box Description</label>
    <input type="text" name="boxDescription" onChange={boxDescriptionHandler} defaultValue={boxDescription}></input>

    
   
</div>

</Modal>


    );
}

export default UpdateForm;