import react from 'react';


const SearchByEmp = ({fetchToolOnSiteByEmp, employees, id}) =>{
    //console.log(props)
    return (
<select onChange={(e)=>{fetchToolOnSiteByEmp(e.target.value)}}>
    <option>Employee</option>
    {
employees.map(({emp_name})=>{
return(<option key={id} value={emp_name}>{emp_name}</option>)
})
        
    }
   </select>
   );

}

export default SearchByEmp;