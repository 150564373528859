import axios from "axios";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactSearchBox from "react-search-box";

import Axios from "axios";

import Element from "./Element";

import BoxDropdownMenu from "./BoxDropdownMenu";
import SearchBox from "./components/SearchBox.js";
import Alert from "../components/Alert";
import UpdateForm from "../components/UpdateForm";
import Container from "../components/Container";
import MyData from "../../store/MyData";
import PageContext from "../../store/PageContext";

import { useRef } from "react";
import ToolSearchSelect from "./components/ToolSearchSelect";



const BoxTool = () => {
  const [BoxToolList, setBoxToolList] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [idUpdateDelete, setIdUpdateDelete] = useState(0);
  const [currentRecord, setCurrentRecord] = useState({});

  const [loading, setLoading] = useState(false);
  const myData = useContext(MyData);

  const pageContext = useContext(PageContext);

  const [deleteResponse, setDeleteResponse] = useState("");
  const [boxSelected, setBoxSelected] = useState(1);
  const [filterBoxWise, setFilterBoxWise] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [toolName, setToolName] = useState("");

  const [data1, setData1] = useState([]);

  // const [updated, setUpdated] = useState(false);/* when tool is issued it update boxtool records */

  const navigate = useNavigate();
  const [currentBoxNo, setCurrentBoxNo] = useState(0);
  const [expendRow, setExpendRowId] = useState("");
  const [expendedRow, setExpendedRowId] = useState("");

  var boxWise = [];
  var box_id = 1;
  var toolBoxFiltered = [];

  const { url_data } = useParams();
  useEffect(() => {
    setCurrentBoxNo(+url_data);
    //console.log("box id fetched from issuetool form")
    //console.log(url_data)
  }, []);

  const FetchBoxRecordHandler = () => {
    setLoading(true);

    Axios.get(process.env.REACT_APP_BASE_URL + "/boxList")
      .then((responce) => {
        if (responce.data) {
          myData.setBox(responce.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(false);
  }; //end  function

  useEffect(() => {
    if (myData.boxRecord.length === 0) {
      FetchBoxRecordHandler();
    }
    pageContext.setCurrentPage("boxtool");
  }, []);
 
  const FetchEmpRecordHandler = () => {
    //  console.log(myData.empRecord.length);

    Axios.get(process.env.REACT_APP_BASE_URL + "/emplist")
      .then((responce) => {
        // console.log(responce.data);
        if (responce) {
          //    setEmpList(responce.data);
          myData.setEmpRecordF(responce.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }; //end  function

  useEffect(() => {
    if (myData.empRecord.length === 0) {
      FetchEmpRecordHandler();
    } // end if condition
  }, []);

 

  const FetchRecordHandler = () => {
    setLoading(true);

    //console.log("fetching data");
    Axios.get(process.env.REACT_APP_BASE_URL + "/boxtoolList")
      .then((responce) => {
        // console.log(responce.data);
        if (responce) {
          myData.setBoxTool(responce.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(false);
  }; //end  function

  useEffect(() => {
    if (myData.boxToolRecord.length === 0) {
      FetchRecordHandler();
    } // end if condition
  }, []);

  useEffect(() => {
    FetchRecordHandler();
  }, [url_data]);

  const FetchToolRecordHandler = () => {
    setLoading(true);

    // console.log(myData.toolListVar.length);
    if (myData.toolListVar.length === 0) {
      //  console.log("call fetch in tool list")

      Axios.get(process.env.REACT_APP_BASE_URL + "/toollistrecord")
        .then((responce) => {
          if (responce.data) {
            // setboxList(responce.data);
            myData.setToolList(responce.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }; //end  function

  useEffect(() => {
    FetchToolRecordHandler();
  }, []);

  /**
   * end data load
   */

  const AlerthideModalHandler = (value) => {
    //  console.log("value is" + value);
    setShowDeleteModal(false);

    if (value) {
      deletRecord();
    }
  };

  const deleteWord = (id) => {
    setShowDeleteModal(true);
    setIdUpdateDelete(id);
    //   if(){deletRecord();}
    //  console.log(id);
  };

  const updateWord = (record) => {
    setCurrentRecord(record);
    setShowUpdateModal(true);

    // console.log("Current Rec Obj " +record.word);
  };

  const hindUpdateWord_submit = () => {
    setShowUpdateModal(false);
  };

  const deletRecord = () => {
    Axios.delete(
      process.env.REACT_APP_BASE_URL + `/delete/${idUpdateDelete}`
    ).then((response) => {
      setDeleteResponse(response);
      //console.log(response.data);
    });
  };

  /**
   * tool issue start
   */
  function issueToolhandler(arg) {
    const boxid = arg.boxId;

    navigate(
      "/add-tool-issue-auto/" +
        arg.boxId +
        "/" +
        arg.toolName +
        "/" +
        arg.totalToolQut +
        "/" +
        arg.recordId
    );
  }

  useEffect(() => {
    Axios.get(process.env.REACT_APP_BASE_URL + "/boxtoolList-byBox", {
      params: {
        box_no: currentBoxNo,
      },
    })
      .then((responce) => {
        if (responce) {
          //  myData.setBoxTool(responce.data);
          setFilterBoxWise(responce.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentBoxNo, myData.boxToolRecord.length, myData.boxToolRecord]);

  const boxSelecteValueHandler = (e) => {
    box_id = parseInt(e.target.value);
    setCurrentBoxNo(box_id);
  };
  //console.log(myData.boxToolRecord);
  //console.log("solve repeat");

  // useEffect(() => {
  //   // const toolBoxFiltered = myData.boxToolRecord.filter((element) => {
  //   //   //return element.tool_name.toLowerCase() === toolName.toLowerCase();
  //   //   // if(element.tool_name.toLowerCase().match(toolName.toLowerCase()))
  //   //   // return element.tool_name;
  //   //  return element.tool_name.toLowerCase().match(toolName.toLowerCase())

  //   // });
  //  // setFilterBoxWise(toolBoxFiltered);
  // }, [toolName]);//myData.toolListVar

  const tool_to_be_searched = (arg) => {
    const serchText = arg;
    console.log(serchText);
    setToolName(arg);
setTimeout(()=>{

  Axios.get(process.env.REACT_APP_BASE_URL + "/boxtoolList-search", {
    params: {
      tool_name: serchText,
    },
  })
    .then((responce) => {
    
      if (responce) {
        //  myData.setBoxTool(responce.data);
        setFilterBoxWise(responce.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });


},400)
  
  }; // search tool end

  const addBoxtools_directHandler = () => {
    console.log(currentBoxNo);
    navigate("/addBoxtools-direct/" + currentBoxNo);
  };

  const expendRowHandler = (e, boxInfo) => {
    //document.getElementById(expendRow).removeChild(expander);
    if(expendedRow !== ""){
  const para = document.getElementById(expendedRow);
  para.removeChild(para.firstElementChild);
    }
   
    const rowId = e.recordId.toString();
    setExpendRowId(rowId);
    const expander = document.createElement("p");
    expander.innerHTML = boxInfo[0].extra;
    expander.style.color="red";
    expander.style.border = "1px solid #aaa";
    document.getElementById(rowId).appendChild(expander);
  };
  useEffect(() => {
     setExpendedRowId(expendRow);
  }, [expendRow]);

  return (
    <Fragment>
     
        <Container>
      <div className="bg__color">
        <div className="elign__element__right">
          <div className="flex-items">
            <SearchBox
              placeholder="Search"
              value=""
              toolToBeSearched={tool_to_be_searched}
            />
          </div>

          <div className="flex-items">
            <BoxDropdownMenu
              boxData={myData.boxRecord}
              boxSelecteNoHandler={boxSelecteValueHandler}
            ></BoxDropdownMenu>
          </div>
          <div className="flex-items">
            <button className="add_button" onClick={addBoxtools_directHandler}>
              + Tool
            </button>
          </div>
        </div>
      </div>
    
        {showUpdateModal && (
          <UpdateForm
            hindUpdateWord_submit={hindUpdateWord_submit}
            cr={currentRecord}
            // refreshForm={refFormAfterUpdate}
          />
        )}
        {showDeleteModal && (
          <Alert AlerthideModalHandler={AlerthideModalHandler} />
        )}
<div class="title_bar">My tools in boxes</div>
        <table>
          <tr>
            <th>Tool Name</th>
            <th>B. Detail</th>

            <th>Box Location</th>
            <th>Tool Q</th>
            <th>Action</th>
          </tr>
          {filterBoxWise.map((element) => {
          
            return (
              <Element
                key={element.id}
                recordId={element.id}
                hl={myData.rowHilightK}
                boxId={element.box_id}
                toolName={element.tool_name}
                totalToolQut={element.tool_qut}
                boxInfo={myData.boxRecord}
                deleteWord={deleteWord}
                updateWord={updateWord}
                issueTool={issueToolhandler}
                addRow={expendRowHandler}
              ></Element>
            );
          })}
        </table>
      </Container>
    </Fragment>
  ); //end of return
};

export default BoxTool;
