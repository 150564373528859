import { useState, useContext } from "react";
import ToolOnSiteRow from "./ToolOnSiteRow";
import ToolContext from "../../context/toolContext";
//importAlertModal from '../../components/Modal/Modal.js';
import AlertModal from './Modal/Modal.js';

const Table = ({ toolsOnSite, id }) => {
 const { trashTool_ToolOnSite, handlerReturnToll } = useContext(ToolContext);
  const [toolTrashAlert, setToolTrashAlert ] = useState(false);
  const [toolDeleteId, setToolDeleteId ] = useState({});
  const messageAlertModal = "Do you want to delete this tool.";
  var trashTool_ToolOnSite1 = (id,tool_qut) => {
    console.log("test", id, tool_qut);
    setToolTrashAlert(true);
    setToolDeleteId({id,tool_qut});
   
  }
  const onCloseAlertModal = () => {
    setToolTrashAlert(false);
    trashTool_ToolOnSite(toolDeleteId);
  }
  const onClickCancel = () => {
    setToolTrashAlert(false);
  }

  
 
  const renderRows = toolsOnSite.map((toolOnSite) => {
    return <ToolOnSiteRow key={id} toolOnSite={toolOnSite} trashTool_ToolOnSite={trashTool_ToolOnSite1} handlerReturnToll={handlerReturnToll}/>;
  });



  return (
    <div>
     { toolTrashAlert && <AlertModal onClickRight={onCloseAlertModal} onClickLeft={onClickCancel} message={messageAlertModal} rightBtnText={"Ok"} leftBtnText={"Cancel"}/>}   
      <table>
        <tr>
          <th>Tool Name</th>
          <th>Q</th>
          <th>Emp Name</th>
          <th>B</th>
          <th>Date</th>
          <th>Action</th>
        </tr>
        {renderRows}
      </table>
    </div>
  );
};
export default Table;
