import react from 'react';
import ReactDom from 'react-dom';


const SearchBox = (props) =>{

    const searchTool = (e) =>{
        e.preventDefault();
    }
    const findTool = (e) => {
        const serchText = e.target.value;
        console.log(serchText);
        props.toolToBeSearched(serchText);
        
    }
    
    return(
    <div>
        {/* <form onSubmit={searchTool}> */}
    <input type="search" name="search" 
    onChange={findTool}
    id="search" placeholder={props.placeholder}></input>
       {/* <button type="submit">OK</button> */}
{/* </form> */}
    </div>
    );
}

export default SearchBox;