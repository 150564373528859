import React, {useState} from "react";
import Modal from "./Modal";
import Button from "./Button";
import './delete_updateForm.css'
import Axios from'axios';

const UpdateForm = (props) =>{
const {cr} = props;

/* cature form data */
const [updateId, setUpdateId] = useState(cr.id);
const [word, setWord] = useState(cr.word);
const [wordMeaning, setWordMeaning] = useState(cr.meaning);
const [wordSimilar, setWordSimilar] = useState(cr.similar);
const [exSentence, setExSentence ] = useState(cr.sentence);
const [wordLink, setWordLink ] = useState(false)
const [wordType, setWordType ] = useState("")



const wordHandler = (event) => {
    event.preventDefault();
    setWord(event.target.value);
    console.log(word);
    }
    
    const wordMeaningHandler = (event) => {
    event.preventDefault();
    setWordMeaning(event.target.value);
    console.log(wordMeaning);
    }
    
    
    const similarHandler = (event) =>{
      setWordSimilar(event.target.value)
    }
    
    const sentenceHandler = (event) =>{
      setExSentence(event.target.value)
    }
    
    const moreHandler = (event) =>{
      setWordLink(event.target.value)
    }
    
    const wordTypeHandler = (event) =>{
      console.log("wordTypeHandler");
    setWordType(event.target.value);
    
    }
    /*END cature form data */

const updateRecord = (event) =>{

    event.preventDefault();
    //Axios.post("https://gstadeveloper.com/vocabulary_server_url/create",{
      Axios.put(process.env.REACT_APP_BASE_URL+`/update/${updateId}`,{
    word: word,
    wordMeaning: wordMeaning,
    wordSimilar: wordSimilar,
    exSentence: exSentence,
    wordLink: wordLink,
    wordType: wordType
    }).then((response)=>{
     // console.log(response)
     props.refreshForm(response);
    });


    props.hindUpdateWord_submit();
} 

//console.log("get objec value "+cr.id)
    return (
<Modal>

<div className="update__form">
<div className="button__container">
<Button onClick={updateRecord}>Submit</Button>
<Button onClick={props.hindUpdateWord_submit}>Cancel</Button>
</div>
    <h3>Modify word data</h3>
    <label htmlFor="Word">Word</label>
    <input type="text" name="word" onChange={wordHandler} defaultValue={cr.word} ></input>

    <label htmlFor="Meaning">Meaning</label>
    <input type="text" name="meaning" onChange={wordMeaningHandler} defaultValue={cr.meaning}></input>

    <label htmlFor="Similar" >Similar</label>
    <input type="text" name="similar" onChange={similarHandler} defaultValue={cr.similar}></input>

    <label htmlFor="sentence" >Sentence</label>
    <input type="text" name="sentence" onChange={sentenceHandler} defaultValue={cr.sentence}></input>

</div>

</Modal>


    );
}

export default UpdateForm;