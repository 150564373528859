import axios from "axios";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Element from "./Element";
import Alert from "../components/Alert";
import UpdateForm from "../components/UpdateForm";
import Container from "../components/Container";
import MyData from "../../store/MyData";

const Toollist = () => {
  // const [toolList, setToolList] = useState([]);
  // const [boxList, setBoxList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [idUpdateDelete, setIdUpdateDelete] = useState(0);
  const [currentRecord, setCurrentRecord] = useState({});

  const myData = useContext(MyData);

  const [deleteResponse, setDeleteResponse] = useState("");
  const [boxSelected, setBoxSelected] = useState(1);
  const [loading, setLoading] = useState(false);

  const FetchToolRecordHandler = () => {
    setLoading(true);

    // console.log(myData.toolListVar.length);
    if (myData.toolListVar.length === 0) {
      //  console.log("call fetch in tool list")

      Axios.get(process.env.REACT_APP_BASE_URL + "/toollistrecord")
        .then((responce) => {
          if (responce.data) {
            // setboxList(responce.data);
            myData.setToolList(responce.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }; //end  function

  useEffect(() => {
    FetchToolRecordHandler();
  }, []);


  const FetchToolRecordHandler_when_deleted = () => {
    
      //  console.log("call fetch in tool list")

      Axios.get(process.env.REACT_APP_BASE_URL + "/toollistrecord")
        .then((responce) => {
          if (responce.data) {
            // setboxList(responce.data);
            myData.setToolList(responce.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
   
  }; //end  function


  /**
   * end data load
   */

  const AlerthideModalHandler = (value) => {
    console.log("value is" + value);
    setShowDeleteModal(false);

    if (value) {
      deletRecord();
    }
  };

  const delete_Tool = (id) => {
    setShowDeleteModal(true);
    setIdUpdateDelete(id);

    console.log(id);
  };

  const updateWord = (record) => {
    setCurrentRecord(record);
    setShowUpdateModal(true);

    // console.log("Current Rec Obj " +record.word);
  };

  const hindUpdateWord_submit = () => {
    setShowUpdateModal(false);
  };

  const deletRecord = () => {
    Axios.delete(
      process.env.REACT_APP_BASE_URL + `/delete_tool/${idUpdateDelete}`
    ).then((response) => {
      setDeleteResponse(response);
      FetchToolRecordHandler_when_deleted();
      console.log(response.data);
    });
  };
  const refFormAfterUpdate = () => {
    //this is function is just for refresh form
  };

  const setOptionValueHandler = (props) => {
    //console.log("th "+ props);

    setBoxSelected(+props);
  };

  return (
    <Fragment>
      <Container>
       
        {showUpdateModal && (
          <UpdateForm
            hindUpdateWord_submit={hindUpdateWord_submit}
            cr={currentRecord}
            refreshForm={refFormAfterUpdate}
          />
        )}
        {showDeleteModal && (
          <Alert AlerthideModalHandler={AlerthideModalHandler} />
        )}
 <div class="title_bar">Tools detail</div>
        <table>
          <tr>
            <th>Tool Name</th>
           
            <th>Description</th>
            <th>Action</th>
          </tr>
          {myData.toolListVar.map((element) => {
            return (
              <Element
                key={element.id}
                id={element.id}
                deleteTool={delete_Tool}
                updateWord={updateWord}
              >
                {element}
              </Element>
            );
          })}
        </table>
      </Container>
    </Fragment>
  ); //end of return
};

export default Toollist;
