import axios from "axios";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Element from "./Element";
import SelectFilter from "../components/BoxFilter";
import Alert from "../components/Alert";
import UpdateForm from "./components/UpdateForm";
import MyData from "../../store/MyData";
import Containers from "../components/Container";

const UpdateRemove = () => {
  const [boxList, setboxList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [idUpdateDelete, setIdUpdateDelete] = useState(0);
  const [updateBox_id, setUpdateBox_id] = useState(0)
  const [updateBoxLocation, setupdateBoxLocation] = useState("");
  const [updateBoxDesctirption, setUpdateBoxDesctirption ] = useState("")

  const [deleteResponse, setDeleteResponse] = useState("");

  const myData = useContext(MyData);
  const [loading, setLoading] = useState(false);

  const FetchBoxRecordHandler = () => {
    setLoading(true);

    // console.log(myData.boxRecord.length);
    if (myData.boxRecord.length === 0) {
      // console.log("call fetch in box list")
      Axios.get(process.env.REACT_APP_BASE_URL + "/boxList")
        .then((responce) => {
          if (responce.data) {
            console.log(responce.data);
            // setboxList(responce.data);
            myData.setBox(responce.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }; //end  function

  useEffect(() => {
    FetchBoxRecordHandler();
  }, []);


  const FetchBoxRecordHandler_when_deleted = () => {
    
    Axios.get(process.env.REACT_APP_BASE_URL + "/boxList")
    .then((responce) => {
      if (responce.data) {
      //  console.log(responce.data);
        // setboxList(responce.data);
        myData.setBox(responce.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
 
}; //end  function



  /**
   * End data load
   */

  const AlerthideModalHandler = (value) => {
    console.log("value is" + value);
    setShowDeleteModal(false);

    if (value) {
      deletBoxRecordConfermed();
    }
  };

  const delete_box = (id) => {
    setShowDeleteModal(true);
    setIdUpdateDelete(id);

    //  console.log(id);
  };

  const updateBoxHandler = (props) => {
   
   // console.log(props);
   
    
    setupdateBoxLocation(props.location);
    setUpdateBoxDesctirption(props.description);
    setUpdateBox_id(props.box_id);
    setShowUpdateModal(true);

     
  };

  const hindUpdateBox_submit = () => {
    setShowUpdateModal(false);
  };

  const deletBoxRecordConfermed = () => {
    Axios.delete(
      process.env.REACT_APP_BASE_URL + `/delete_box/${idUpdateDelete}`
    ).then((response) => {
      setDeleteResponse(response);
      FetchBoxRecordHandler_when_deleted();
      //console.log(response.data.length);
    });
  };
  

 
  const setOptionValueHandler = (props) => {
    //console.log("th "+ props);
  };
  //console.log("this");
  //    console.log(myData.boxRecord);

  return (
    <Fragment>
      <Containers>
     
       
        {showUpdateModal && (
          <UpdateForm
            hindUpdateBox_submit={hindUpdateBox_submit}
            box_id={updateBox_id}
            boxLocation={updateBoxLocation}
            boxDescription={updateBoxDesctirption}
            refreshForm={FetchBoxRecordHandler_when_deleted}
          />
        )}
        {showDeleteModal && (
          <Alert AlerthideModalHandler={AlerthideModalHandler} />
        )}
 <div class="title_bar">Box detail</div>
        <table>
          <tr>
            <th>Box No</th>
            <th>Location</th>
            <th>Description</th>
            <th>Action</th>
          </tr>

          {loading &&
            myData.boxRecord.map((element) => {
            //    console.log(element);
              return (
                <Element
                  key={element.id}
                  id={element.id}
                  box_id={element.box_id}
                  location={element.location}
                  description={element.extra}
                  deleteBoxRecord={delete_box} 
                  updateBox={updateBoxHandler}
                >
                  {element}
                </Element>
              );
            })}
        </table>
        {!loading && <div>loading...</div>}
      </Containers>
    </Fragment>
  ); //end of return
};

export default UpdateRemove;
