import {  Fragment, useEffect, useContext, useState } from "react";


import classes from "./component/style.module.css";
import Box from "./pages/box/Box";
import Addbox from "./pages/box/Addbox";

import BoxTool from "./pages/box-tool/Boxtool";
import BoxToolEditor from "./pages/box-tool-editor/BoxtoolEditor";
import UpdateToolBox from "./pages/box-tool-editor/UpdateToolBox";
import AddBoxTools from "./pages/box-tool/AddBoxTools";
import AddBoxtoolsInEditerDirect from "./pages/box-tool-editor/AddBoxToolsInEditerDirect";
import AddBoxToolsDirect from "./pages/box-tool/AddBoxToolsDirect";

import Toollist from "./pages/tool-list/Toollist";
import Addtoollist from "./pages/tool-list/Addtoollist";


import Employee from "./pages/employee/Employee";

import EmployeeForm from "./pages/employee/employeeForm";

import AddToolissueAuto from "./pages/box-tool/AddToolissueAuto";


import ToolOnSite from "./pages/tool-on-site/ToolOnSite";
import { BrowserRouter, Route, Routes, Link, useNavigate } from "react-router-dom";

import MyData, { DataProvider } from "./store/MyData";
import {PageValueProvider} from "./store/PageContext";
import "./index.css";
import Axios from "axios";
//context
import { ToolContextProvider } from './context/toolContext';
//import ToolContext from './context/toolContext'
import FilesUploadComponent from "./pages/upload/file-upload-component";
// side bar
import Header from "./components/Layout/Header";
import Sidebar from "./components/Sidebar/Sidebar";

// side bar link resourse

import Test from "./Test.js";

function App() {
  const navigate = useNavigate();

  const myData = useContext(MyData);
  const [showSidebar, setShowSidebar] = useState(true);

  const FetchBoxRecordHandler = () => {
 
    Axios.get(process.env.REACT_APP_BASE_URL + "/boxList")
      .then((responce) => {
        if (responce.data) {
          myData.setBox(responce.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  
  }; //end  function

  const FetchToolIssueRecordHandler = () => {
    
    Axios.get(process.env.REACT_APP_BASE_URL + "/toolissuerecord")
      .then((responce) => {
    
        if (responce) {
         
          myData.setToolIssue(responce.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

 
  }; //end  function

  useEffect(() => {
    FetchBoxRecordHandler();

    FetchToolIssueRecordHandler();

    myData.FetchTooIssueRecord();

  }, []);

  useEffect(() => {
    myData.FetchEmpRecord();
    myData.FetchBoxToolRecord();
  
  }, []);

  useEffect(() => {
    myData.FetchEmpRecord();
    myData.FetchBoxToolRecord();
 
  }, []);
 
  /**
   * sidebar
   */
  const [cartIsShown, setCartIsShown] = useState(false);

  const showCartHandler = () => {
    setCartIsShown(true);
  };

  const hideCartHandler = () => {
    setCartIsShown(false);
  };

  /**
   * End sidebar
   */

   

  return (
    <Fragment>
      <ToolContextProvider>
        <DataProvider>
        <PageValueProvider>
      {cartIsShown && <Sidebar onClose={hideCartHandler} />}
      <Header onShowSideBar={showCartHandler} />

    
      
        <div className={classes.container_main}>
       

          <Routes>
          <Route exact path="/" element={<ToolOnSite />} />
            <Route path="/addBoxtools-direct/:boxNo" element={<AddBoxToolsDirect />} />
           
            <Route path="/box" element={<Box />} />
            <Route path="/addbox" element={<Addbox />} />

            <Route path="/boxtool/:url_data" element={<BoxTool />} />
            <Route path="/addboxtools" element={<AddBoxTools />} />
            <Route path="/addBoxtoolsInEditer-direct/:url_data" element={<AddBoxtoolsInEditerDirect />} />
            
            <Route path="/boxtooleditor/:url_data" element={<BoxToolEditor />} />

            <Route path="/toollist" element={<Toollist />} />
            <Route path="/addtoollist" element={<Addtoollist />} />

            <Route path="/employee" element={<Employee />}></Route>

            <Route path="/employeeform" element={<EmployeeForm />} />
            {/* <Route path="/add-tool-issue/:issue_box_id/:issue_tool_name/:qut_max"  element={<AddToolissue />} /> */}
            <Route
              path="/add-tool-issue-auto/:issue_box_id/:issue_tool_name/:qut_max/:box_record_Id"
              element={<AddToolissueAuto />} />

           <Route
              path="/update-box-toll/:issue_box_id/:issue_tool_name/:qut_max/:box_record_Id"
              element={<UpdateToolBox />}  />

            <Route path="/test" element={<Test />} />
            <Route element={<ToolOnSite />} />
            <Route path="/upload" element={<FilesUploadComponent />} />
          </Routes>
        </div>
        </PageValueProvider>
      </DataProvider>
      </ToolContextProvider>
    </Fragment>
  );
}

export default App;
