import React,{useEffect, useState,useContext} from 'react';

import Button from '../../component/Button';
import Card from '../../component/Card';
import Axios from'axios';
import classes from '../../component/style.module.css'
import MyData from '../../store/MyData';
// import '../../component/form.css'


const Addtool = () => {
const [box_id, setbox_id] = useState("");
const [location, setlocation] = useState("");
const [tool, settool] = useState("");
const [extra, setextra ] = useState('');
const [abc, setabc ] = useState(false)
const [xyz, setxyz ] = useState("")
const [box_idList, setbox_idList] = useState([]);
const [lastbox_id, setLastbox_id] = useState([]);
const [active, setActive] = React.useState(0);
const [submitResponse, setSubmitResponse] = useState([]);

const [toolNameEmpty, setToolNameEmpty] = useState(false);

const myData = useContext(MyData);

const boxHandler = (event) => {
event.preventDefault();
setbox_id(event.target.value);
//console.log(box_id);
}

const locationHandler = (event) => {
event.preventDefault();
setlocation(event.target.value);
//console.log(location);
}


const toolHandler = (event) =>{
  settool(event.target.value)
}

const extraHandler = (event) =>{
  setextra(event.target.value)
}

const moreHandler = (event) =>{
  setabc(event.target.value)
}

const xyzHandler = (event) =>{
 // console.log("xyzHandler");
setxyz(event.target.value);

}

const submitHandler = (event) => {
  event.preventDefault();

    /**
     * form validaton
     */
     if (tool.trim() === "") {
      //  alert("Please Select Employee");
      setToolNameEmpty(true);
      return;
    }
    setToolNameEmpty(false);

  
  
  Axios.post(process.env.REACT_APP_BASE_URL+"/addtoollist",{
box_id: box_id,
location: location,
tool: tool,
extra: extra,
abc: abc,
xyz: xyz
}).then((response)=>{
  //console.log(response.data.affectedRows)
  setbox_id('');
  setlocation('');
  settool('')
  setextra('')
//setSubmitResponse(response.data.affectedRows);
setSubmitResponse(response.data);
});


}



const FetchBoxRecordHandler =  () => {

   
    Axios
    .get(process.env.REACT_APP_BASE_URL + "/toollistrecord")
    .then((responce) => {
      if (responce.data) {
     
     // setboxList(responce.data);
      myData.setToolList(responce.data);
     
      }
    })
    .catch((err) => {
      console.log(err);
    });
  

  
};//end  function


useEffect(() => {
    
  FetchBoxRecordHandler();

 }, [submitHandler]);





    return (
      <div className = "container">
          <div class="title_bar">Add new tools</div>
  <Card 
  css_class={"card"}
  css_h1={"card"}
  text={"card_text"}
 
  >
    <h1 className="card_h1">Create Tool List.</h1>
<form method="post" onSubmit={submitHandler}>
   
    <label htmlFor="meaning" >Tool</label>
    <input type="text" name="similar" value={tool} onChange={toolHandler}></input>
    <label htmlFor="meaning" >Description</label>
    <input type="text" name="setnece" value={extra} onChange={extraHandler}></input>
   

     <div className="radio_group">

   
    <Button type="submit" css_class={"save_btn"}>Save</Button>
   </div>
</form>

 



{toolNameEmpty && <h3>!! Please fill Tool Name</h3>}

  </Card>
  

  

 </div>
    );
} 

export default Addtool;