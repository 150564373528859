import  React from 'react';
import classes from './ui.module.css';
const Card = (props) => {

    return(
        <div className={`${props.css_class} ${classes.input} ${props.text} `}>{props.children}</div>
    )
}

export default Card;
