import { Fragment } from "react";
import ReactDOM from "react-dom";
import classes from "./Modal.module.css";

const Backdrop = ({ onClose }) => {
  return <div className={classes.backdrop} onClick={onClose} />;
};

const ModalOverlay = (props) => {
  return (
    <div className={classes.modal}>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

const Modal = ({
  onClickRight = null,
  message = null,
  onClickLeft = null,
  rightBtnText = null,
  leftBtnText = null,
}) => {
  return ReactDOM.createPortal(
    <Fragment>
      <Backdrop onClick={onClickRight} />
      <ModalOverlay>
        <div className={classes.colContainer}>
          <div>{message}</div>
          <div className={classes.rowContainer}>
            <div className={classes.justify_end}>
              {" "}
              {onClickRight && (
                <button onClick={onClickRight}>{rightBtnText}</button>
              )}
            </div>
            <div className={classes.justify_end}>
              {onClickLeft && (
                <button onClick={onClickLeft}>{leftBtnText}</button>
              )}
            </div>
          </div>
        </div>
      </ModalOverlay>
    </Fragment>,
    document.querySelector(".modal-container")
  );
};

export default Modal;
