import axios from 'axios';
import { createContext, useState } from 'react';

const ToolContext = createContext();


export const ToolContextProvider = ({children}) => {

    const [toolsOnSite, setToolOnSite ] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [boxs, setBoxs ] = useState([]);

    const fetchBoxs = async () => {
        const response = await axios.get(process.env.REACT_APP_BASE_URL + "/boxList");
        setBoxs(response.data);
    }

const fetchEmployees = async () => {
const response = await axios.get(process.env.REACT_APP_BASE_URL + "/emplist");
setEmployees(response.data);

}


    const fetchToolOnSite = async () => {
     const response = await axios.get(process.env.REACT_APP_BASE_URL + "/toolissuerecord");
          
           setToolOnSite( response.data);
          
    }

    const debounce = (func, delay = 300) => {
     
        let timeoutId;
        return (...args) => {
         
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          timeoutId = setTimeout(() => {
            func.apply(null, args);
          }, delay);
        };
      };




    const searchToolOnSite = debounce(async (searchText) => {
        // console.log("search cleed");
      const response = await axios.get(process.env.REACT_APP_BASE_URL + `/toolissuerecord/${searchText}`)
      setToolOnSite(response.data);
    });

    const fetchToolOnSiteByEmp = async (empName) => {
        console.log(empName);
 const response = await axios.get(process.env.REACT_APP_BASE_URL +`/searchToolOnSiteByEmpName/${empName}`)
 setToolOnSite(response.data);
}

const fetchToolOnSiteByBoxId = async (boxId) => {
    const response = await axios.get(process.env.REACT_APP_BASE_URL + `/toolOnSiteSearchByBoxId/${boxId}`) ;
    setToolOnSite(response.data);
}

const trashTool_ToolOnSite = async ({id,tool_qut}) => {
    console.log("delete", id, tool_qut)

   const response = await axios.delete(process.env.REACT_APP_BASE_URL +`/distroy-tool-from-toolOnSite/${id}/${tool_qut}`);
   console.log(response);

if(response.data.changedRows){
    console.log(`One tool out of ${tool_qut} is destroyed `);
}else{
    console.log("Tool is destroyed");
}
fetchToolOnSite();
     
   }

   const handlerReturnToll = async (onSiteToolData) => {
   console.log(onSiteToolData);
   const {id,box_id,tool_id,tool_name, tool_qut}= onSiteToolData;
   console.log(id);
   const response = await axios.put(process.env.REACT_APP_BASE_URL +`/add-return`,{
    onSiteToolData
})

   }

const value = {
    boxs,
    fetchBoxs,
    employees,
    fetchEmployees,
    fetchToolOnSiteByEmp,
    toolsOnSite,
    fetchToolOnSite,
    searchToolOnSite,
    fetchToolOnSiteByBoxId,
    trashTool_ToolOnSite,
    handlerReturnToll,
}

return (<ToolContext.Provider value={value}>
    {children}
</ToolContext.Provider>);

}

export default ToolContext;
