
const SearchBox = ({ searchHanler, placeholder, value }) => {
  
  const findTool = (e) => {
    setTimeout(() => {
      const serchText = e.target.value;
      if(serchText){searchHanler(serchText);}
    }, 300);
  };

  return (
    <div>
      <input
        type="search"
        name="search"
        onChange={findTool}
        id="search"
        placeholder={placeholder}
      ></input>
    </div>
  );
};

export default SearchBox;
