import React, { useEffect, useState, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import EmployeeList from "./EmployeeList";
import MyData from "../../store/MyData";
import Containers from "../components/Container";
import UpdateForm from "../components/UpdateForm";
import Alert from "../components/Alert";
import Axios from "axios";

const Employee = () => {
  const [empList, setEmpList] = useState([]);
  const myData = useContext(MyData);
  const [loading, setLoading] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [idUpdateDelete, setIdUpdateDelete] = useState(0);
  const [currentRecord, setCurrentRecord] = useState({});

  const FetchRecordHandler = () => {
    setLoading(true);
  //  console.log(myData.empRecord.length);
    if (myData.empRecord.length === 0) {
      Axios.get(process.env.REACT_APP_BASE_URL + "/emplist")
        .then((responce) => {
          // console.log(responce.data);
          if (responce) {
            setEmpList(responce.data);
            myData.setEmpRecordF(responce.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } // end if condition

    setLoading(false);
  }; //end  function

  useEffect(() => {
    FetchRecordHandler();
  }, []);

  const FetchRecordHandler_when_data_changed = () => {
    Axios.get(process.env.REACT_APP_BASE_URL + "/emplist")
      .then((responce) => {
        // console.log(responce.data);
        if (responce) {
          setEmpList(responce.data);
          myData.setEmpRecordF(responce.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }; //end  function

  /**
   * End data load
   */

  const hindUpdateWord_submit = () => {
    setShowUpdateModal(false);
  };

  const deletRecord = () => {
    Axios.delete(
      process.env.REACT_APP_BASE_URL + `/delete_employee/${idUpdateDelete}`
    ).then((response) => {
      //  setDeleteResponse(response);
      FetchRecordHandler_when_data_changed();
      console.log(response.data);
    });
  };

  const AlerthideModalHandler = (value) => {
    console.log("value is" + value);
    setShowDeleteModal(false);

    if (value) {
      deletRecord();
    }
  };

  const delete_emp = (id) => {
    setShowDeleteModal(true);
    setIdUpdateDelete(id);

    console.log(id);
  };

  return (
    <Fragment>
      <Containers>
       
        {showUpdateModal && (
          <UpdateForm
            hindUpdateWord_submit={hindUpdateWord_submit}
            cr={currentRecord}
            // refreshForm={refFormAfterUpdate}
          />
        )}
        {showDeleteModal && (
          <Alert AlerthideModalHandler={AlerthideModalHandler} />
        )}
     <div class="title_bar">Employee detail</div>
        <table>
          <tr>
            <th>Emp Id</th>
            <th>Name</th>
            <th>Action</th>
          </tr>
          {myData.empRecord.map((emp) => {
            return (
              <EmployeeList
                key={emp.id}
                id={emp.id}
                name={emp.emp_name}
                deleteEmp={delete_emp}
              />
            );
          })}
        </table>
      </Containers>
    </Fragment>
  );
};

export default Employee;
